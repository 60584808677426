import React, { useEffect, useState } from "react";
import "./index.css";
import ReactGA from "react-ga4";
const Zomato = ({ isLoad = true }) => {
  const [isLoading, setIsLoading] = useState(isLoad);
  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        window.location.href = "https://link.zomato.com/xqzv/j48ccgfy";
        setIsLoading(false);
      }, 2000);
    } else {
      window.open("https://link.zomato.com/xqzv/j48ccgfy", "_blank");
    }

    // ReactGA.send({ hitType: "pageview", page: "/z", title: "Zomato" });
  }, []);
  return (
    <div>
      {isLoading && (
        <div className="zomato-back">
          <div className="loader"></div>
          {/* <div className="circle-border">
           <div className="logo"></div>
         </div>
         <p>Caution, hot subs ahead.</p> */}
        </div>
      )}
    </div>
  );
};

export default Zomato;
