import React, { useState } from "react";
import "../index.css";
import desktopText from "../../../resources/images/DesktopText.webp";
import productImage from "../../../resources/images/ProductImage.webp";
import swiggy from "../../../resources/images/swiggy.webp";
import zomato from "../../../resources/images/zomato.webp";
import Swiggy from "../../swiggy";
import Zomato from "../../zomato";
import mobileText from "../../../resources/images/MobileText.webp";
import logo from "../../../resources/images/bg-logo.webp";
import homeBackgroundImg from "../../../resources/images/home-img.webp";
import customText from "../../../resources/images/home-text.webp";
import { useNavigate } from "react-router-dom";
import CommonFooter from "../../common-footer";

const FirstSection = () => {
  const [showSwiggy, setShowSwiggy] = useState(false);
  const [showZomato, setShowZomato] = useState(false);
  const navigate = useNavigate();
  const swiggyHandler = () => {
    setShowSwiggy(true);
  };
  const zomatoHandler = () => {
    setShowZomato(true);
  };

  return (
    <>
      <div className="first-section">
        <div className="content-con">
          <div className="upper-section">
            <div className="text-con">
              <img src={customText} className="grill-con" alt="subway" />
              <p className="order-at">Order Now On</p>
              <div className="order-btn">
                <button
                  className="custom-btn swiggy home-order-btn"
                  onClick={swiggyHandler}
                >
                  <img src={swiggy} alt="subway" />
                </button>
                <button
                  className="custom-btn zomato home-order-btn"
                  onClick={zomatoHandler}
                >
                  <img src={zomato} alt="subway" />
                </button>
              </div>
            </div>
          </div>
          <div className="video-div">
            <div className="video-con">
              <iframe
                src="https://www.youtube.com/embed/9KWjPD3r-fU?rel=0"
                title="Subway Hotsellers | Just point, pick and enjoy."
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
                className="video"
              ></iframe>
              <meta itemprop="name" content="Subway Hotsellers"></meta>
              <meta
                itemprop="description"
                content="Just point, pick and enjoy."
              ></meta>
              <meta
                itemprop="thumbnailUrl"
                content="https://i.ytimg.com/vi/9KWjPD3r-fU/mqdefault.jpg"
              ></meta>
            </div>
          </div>
        </div>

        <CommonFooter />
      </div>
      {showSwiggy && <Swiggy />}
      {showZomato && <Zomato />}
    </>
  );
};

export default FirstSection;
