import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PdfViewer from "../privacy-policy/pdf-viewer";

const TermsAndConditions = () => {
  const navigate = useNavigate();
  return (
    <div className="privacy-con">
      <PdfViewer link="https://idev.blob.core.windows.net/technetv2/Subway_Standard%20Terms%20and%20Conditions.docx%20(1)%20-%201712139883961.pdf" />
      <div className="privacy-footer-con">
        <footer className="home-footer">
          <div className="common-footer-con">
            <div>
              <p className="common-footer-header">Contact</p>
              <p
                className="common-footer-ele common-footer-link-ele"
                onClick={() => {
                  const emailAddress = "customercare@subway.in";
                  window.location.href = `mailto:${emailAddress}`;
                }}
              >
                Customer Care
              </p>
              <p
                className="common-footer-ele common-footer-link-ele"
                onClick={() => {
                  window.open("https://feedback.subway.in/talktous", "_blank");
                }}
              >
                Feedback
              </p>
            </div>
            <div>
              <p className="common-footer-header">Subway Cares</p>
              <p
                className="common-footer-ele common-footer-link-ele"
                onClick={() => {
                  window.open(
                    "https://qnet.blob.core.windows.net/central/Nutritional%20%2B%20Allergen%20Chart-%20Version%202.44%20August%202024%20-%201724503085038.pdf",
                    "_blank"
                  );
                }}
              >
                Nutrition Information
              </p>
            </div>
            <div>
              <p className="common-footer-header">Legal</p>
              <p
                className="common-footer-ele common-footer-link-ele"
                onClick={() => {
                  navigate("/privacy-policy");
                }}
              >
                Privacy Policy
              </p>
              <p
                className="common-footer-ele common-footer-link-ele"
                onClick={() => {
                  navigate("/terms-and-conditions");
                }}
              >
                Terms & Conditions
              </p>
            </div>
          </div>
          <div className="common-footer-border"></div>
          <div>
            <p className="footer-text">
              All rights reserved. SUBWAY® is a Registered Trademark of Subway
              IP LLC. ©2022 Subway IP LLC., and is licensed to Eversub India
              Private Limited under an exclusive master franchisee arrangement
              for the territory of the Republic of India.
            </p>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default TermsAndConditions;
